import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const ColorCheckbox = withStyles((theme) => ({
  root: {
    color: (props) => props.color,
  '&$checked': {
    color: (props) => props.color
  },
  transform: 'scale(0.7)',
},
checked:{},
}))((props) => <Checkbox color="default" {...props} />);

export default function CustomLegend({display, setDisplay, colors}) {
  const [toggle, setToggle] = useState(false)

  const toggleDisplay = (entity) => {
   setDisplay((prevDisplay) => ({
     ...prevDisplay,
     [entity]: !prevDisplay[entity]
   }));
  };

  const toggleAll = (event) => {
    setToggle(!toggle)
    setDisplay( prevDisplay => {
      const updatedDisplay = {}
      for (let key in prevDisplay) {
        updatedDisplay[key] = toggle
      }
      return updatedDisplay
    })
  }

  return (
    <Grid container spacing={1} style={{display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',}}>
      {Object.keys(display).map((key, index) => (
        <Grid item style = {{marginBottom:-15, display:'flex', alignItems:'center'}}>
          <FormControlLabel
            control = {
              <ColorCheckbox
                id = {key}
                checked = {display[key]}
                onClick = {() => toggleDisplay(key)}
                color = {colors[index]}
                size = 'small'
              />
            }
            label = {<Typography variant="body2">{key}</Typography>}

          />
        </Grid>
      ))}
      <Grid item style = {{marginBottom:-15, display:'flex', alignItems:'center'}}>
        <FormControlLabel
          control = {
            <ColorCheckbox
              checked = {!toggle}
              onClick = {toggleAll}
              size = 'small'
            />
          }
          label = {<Typography variant='body2'>Select All</Typography>}
        />
      </Grid>
    </Grid>
  )
}
